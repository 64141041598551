import React, { useEffect, useState } from "react";

const LoadingModal = ({ show }) => {
  const [currentImage, setCurrentImage] = useState(0);

  const images = [
    "../assets/img/home/effect1.jpg",
    "../assets/img/home/effect2.jpg",
    "../assets/img/home/effect3.jpg",
  ];

  useEffect(() => {
    if (show) {
      const interval = setInterval(() => {
        setCurrentImage((prev) => (prev + 1) % images.length);
      }, 2000); // Change image every 2 seconds

      return () => clearInterval(interval); // Cleanup on unmount
    }
  }, [show]);

  return (
    show && (
      <div style={styles.overlay}>
        <div style={styles.modal}>
          <img src={images[currentImage]} alt="Loading" style={styles.image} />
          <div style={styles.progressContainer}>
            <div style={styles.progressBar}></div>
          </div>
          <p style={styles.message}>전송중...</p>
        </div>
      </div>
    )
  );
};

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  modal: {
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
  },
  image: {
    width: "100%",
    maxWidth: "600px",
    borderRadius: "5px",
  },
  progressContainer: {
    width: "100%",
    backgroundColor: "#ccc",
    borderRadius: "5px",
    overflow: "hidden",
    marginTop: "10px",
  },
  progressBar: {
    width: "100%",
    height: "10px",
    backgroundColor: "#FF5D7A",
    animation: "progress 6s linear infinite",
  },
  message: {
    marginTop: "10px",
    fontSize: "16px",
    color: "#333",
  },
};

export default LoadingModal;
