//= React
import React from "react";
import { Script } from "gatsby";
//= Components
import ScrollToTop from "components/ScrollToTop";

const MainLayout = ({ children, scrollTopText }) => {
  return (
    <>
      {children}
      <ScrollToTop topText={scrollTopText} />
      <Script src="/assets/js/main.js"></Script>
    </>
  );
};

export default MainLayout;
