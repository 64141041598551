import React from "react";
import { Link } from "gatsby";

const Footer = ({ noWave, active, isMobile }) => {
  return (
    <footer
      className={`style-4 ${noWave ? "mt-0 pt-10" : ""}`}
      data-scroll-index="8"
    >
      <div className="container">
        <div className="section-head text-center style-4"></div>
        <div
          className="copywrite text-center"
          style={{ flexDirection: "column", display: "flex", gap: "4px" }}
        >
          <small className="small">
            {"대표 : 나태주   |   사업자 등록번호 : 136-86-03033"}
          </small>
          <small className="small">
            {"문의 : votrecs@votre.kr   |   연락처 : 02-508-5591"}
          </small>
          <small className="small">
            {"주소 : 서울특별시 강남구 테헤란로 69길 14 삼성빌딩"}
          </small>
          <small className="small">{"상호 : (주)보트르내일"}</small>
          <br />
          <small className="small">
            {"© 2024 Copyrights by "}
            <a href="#" className="fw-bold text-decoration-underline">
              VOTRENAEIL
            </a>
            {" All Rights Reserved"}
          </small>
        </div>
      </div>
      {!noWave && (
        <img
          src="/assets/img/footer/footer_4_wave.png"
          alt=""
          className="wave"
        />
      )}
    </footer>
  );
};

/*

<li>
                  <Link to="/page-about-5">회사소개</Link>
                </li>

*/

export default Footer;
